import React from "react"
import BasicButton from "../button/BasicButton"
import { Link } from "gatsby"

export default function CardSecondary({
  children,
  aspectRatio,
  link,
  btnText,
  placeBtnOutside=false,
  showBtn=true
}) {
  const ratio = aspectRatio ? aspectRatio : "auto"
  const inside = placeBtnOutside ? "gap-2" : "cardSecondary-inside"
  const center = placeBtnOutside ? "justify-self-center": "justify-self-end"
  const displayBtn = showBtn ? "" : "hidden"
  return (
    <Link to={link} className={`grid mb-4 ${inside} cardSecondary`}>
      <div
        className={"rounded-xl overflow-hidden custom-shadow-md "}
        style={{ aspectRatio: ratio }}
      >
        {children}
      </div>
      <BasicButton
        text={btnText ? btnText : "レシピを見る"}
        className={`text-xs lg:text-sm z-10 mt-auto pb-2 pr-2 ${center} ${displayBtn}`}
        link={link}
      />
    </Link>
  )
}
