import React from "react"
import Layout from "../../components/Layout/Layout"
import Header from "../../components/Layout/Header"
import ImageFromNewt from "../../components/Atomic/ImageFromNewt"
import BasicButton from "../../components/Atomic/button/BasicButton"
import BlackButton from "../../components/Atomic/button/BlackButton"
import MarkerHead from "../../components/Atomic/Typography/MarkerHead"
import ListedLifestyle from "../../components/Atomic/Post/ListedLifestyle"

import { graphql } from "gatsby"
import Seo from "../../components/Layout/Seo"

export default function index({ data }) {
  const setting = data.allNewtLifeStyleSetting.edges[0].node
console.log(setting.topContent.topArticle);
  function hasTopContnt(article){
    if(article !=null){
      return(
        <>
        <h2>{setting.topContent.topArticle.title}</h2>
          <BasicButton
            text={"記事を読む"}
            link={`/life-style/${setting.topContent.topArticle.slug}`}
            className={"place-self-end "}
          />
        </>
      )
    }else{
      <>
      <h2>記事がありません</h2>
      </>
    }
  }

  return (
    <Layout>
      <Header />
      <div className="ls-top-article">
        <ImageFromNewt
          src={setting.topContent.topImage.src}
          alt={"ohmycottieライフスタイル"}
          className={
            "md:aspect-[2.5/1] aspect-[4/5] object-cover ls-top-article__img"
          }
        />
        <div className="ls-top-article__title">
          {hasTopContnt(setting.topContent.topArticle)}
        </div>
      </div>
      <section className="max-w-lg m-auto py-28 px-6">
        <p
          className="text-sm leading-loose"
          dangerouslySetInnerHTML={{ __html: setting.copy }}
        ></p>
      </section>
      <section className="bg-[#f5f5f5] relative seasons-section">
        <div className="basic-wrapper relative z-10 pb-32">
          <div className="border border-omcDarkGray py-8 md:px-16 px-4">
            <h2 className="font-baby md:text-6xl text-4xl mb-10">
              {setting.title}
            </h2>
            <div className="grid md:grid-cols-7 grid-cols-1 md:grid-rows-3 gap-4">
              <div
                className="text-sm leading-loose md:col-span-3 md:row-span-2 col-start-1 md:row-start-1"
                dangerouslySetInnerHTML={{ __html: setting.seasons }}
              ></div>
              <ImageFromNewt
                src={setting.seasonsImage.src}
                alt={setting.title}
                className={
                  "md:col-span-4 md:row-span-2 md:row-start-2 place-self-end"
                }
              />
              <div
                className="text-[0.5rem] text-omcGray md:row-start-3 md:col-start-1 md:col-span-3 place-self-end"
                dangerouslySetInnerHTML={{ __html: setting.seasons2 }}
              ></div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <MarkerHead text={"Life Style"} />
        <div className="basic-wrapper py-16">
          <ListedLifestyle />
          <div className="flex justify-center pt-16">
            <BlackButton link={"/life-style/page/1"} text={"一覧を見る"} />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const lifeStyleTop = graphql`
  query lifeStyleTop {
    allNewtLifeStyleSetting(limit: 1) {
      edges {
        node {
          copy
          seasons2
          seasons
          title
          seasonsImage {
            src
          }
          topContent {
            topImage {
              src
            }
            topArticle {
              title
              slug
            }
          }
        }
      }
    }
  }
`

export const Head = ({data}) => (
  <>
  <Seo
    title={"Life Style"}
    description={"自分らしい暮らしを見つけるライフスタイルメディア"}
    ogpImgPath={data.allNewtLifeStyleSetting.edges[0].node.topContent.topImage.src}
  />
  
  </>
)
