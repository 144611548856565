import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import CardSecondary from "./CardSecondary"
import ImageFromNewt from "../ImageFromNewt"

export default function ListedLifestyle() {
  const lifestyles = useStaticQuery(graphql`
    query lifestyles {
      allNewtLifestyle(limit: 6) {
        edges {
          node {
            eyecatch {
              src
            }
            title
            slug
            type
          }
        }
      }
    }
  `)
  return (
    <div className="grid md:grid-cols-3 gap-10">
      {lifestyles.allNewtLifestyle.edges.map((data, index) => {
        console.log(data);
        return (
          <div>
            <CardSecondary
              link={`/life-style/${data.node.slug}`}
              btnText={"記事を読む"}
              key={index}
            >
              <ImageFromNewt
                src={data.node.eyecatch.src}
                alt={data.node.title}
                className={'aspect-[3/2] object-cover'}
              />
            </CardSecondary>
            <div className="-mt-2">
            <span className="inline-block border-2 border-omcBlueGray text-omcBlueGray font-alphabet rounded-full px-4 text-xs mb-3 leading-normal">{data.node.type}</span>
            </div>
            
            <p class="line-clamp text-xs">{data.node.title}</p>
          </div>
        )
      })}
    </div>
  )
}
