import React from "react"

export default function MarkerHead({ text }) {
  return (
    <>
      <h1 className="text-4xl text-center relative pb-4 -z-10 bg-[#f9f9f9]">
        <span className="font-alphabet marker-head">{text}</span>
      </h1>
    </>
  )
}
