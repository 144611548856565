import { Link } from "gatsby"
import React from "react"
import { BsArrowUpRightCircle } from "react-icons/bs"

export default function BasicButton({ text, link, className }) {
  return (
    <div className={`inline-block ` + className}>
      <Link
        to={link}
        className="py-1 px-4 flex gap-1 justify-between  rounded-full custom-shadow-md items-center bg-white basic-btn"
      >
        {text}
        <span className="basic-btn-icon"><BsArrowUpRightCircle size={24}  /></span>
        
      </Link>
    </div>
  )
}
