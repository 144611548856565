import React from "react"

export default function ImageFromNewt({ src, alt, className, sizes }) {
  const c = className != null ? className : ""
  return (
    <>
      <img
        src={src}
        srcSet={src}
        sizes={`${sizes != null ? sizes : 100}vw`}
        className={c}
        alt={alt}
      />
    </>
  )
}
