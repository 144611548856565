import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import IconMenuBar from "./IconMenuBar"
import SearchForm from "../Search/SearchForm"
const Logo = "../../images/favicons/logo@2x.png"

export default function Header() {
  return (
    <header className="py-6 custom-shadow-md">
      <div className="basic-wrapper grid gap-6 md:grid-cols-2 place-items-center">
        <div className="contents md:grid gap-4">
          <Link to={`/`} className="grid place-items-center md:block">
            <StaticImage
              src={Logo}
              alt="ohmycottie"
              layout="constrained"
              width={500}
              className="w-[min(500px,80%)] m-auto"
            />
          </Link>
          <IconMenuBar className={"md:hidden"} />
          <div className="lg:w-2/3">
            <SearchForm />
            <p className="text-xs text-omcBlueGray text-right pr-4 pt-2">
              今日の気分でキーワードレレシピ検索
            </p>
          </div>
        </div>

        <div className="hidden md:flex flex-col justify-between gap-8 ml-auto place-self-end">
          {/* <Link
            to={"/"}
            className="border border-omcDarkGray py-2 px-4 place-self-end inline-block hover:text-white hover:bg-omcDarkGray transition-all"
          >
            WEBマガジン配信中
          </Link> */}
          <div>
            <div className="flex gap-2 justify-end text-xs text-omcGray pb-2">
              <a
                href="https://www.youtube.com/channel/UCJv9mKTDw_pPlPKN6KpVpLg"
                target="_blank"
                rel="noreferrer noopener"
              >
                YouTube
              </a>
              <a
                href="https://www.instagram.com/ohmycottie/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Instagram
              </a>
            </div>
            <div className="flex gap-8">
              <Link to={"/"} className="font-alphabet">
                Home
              </Link>
              <Link to={"/recipe/page/1"} className="font-alphabet">
                Recipes
              </Link>
              <Link to={"/life-style"} className="font-alphabet">
                Life Style
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
